/*App.js*/
import React, { useState } from 'react';
import './App.css';

function App() {
  const [betrag, setBetrag] = useState(''); // Betrag inklusive MwSt
  const [trinkgeldProzentsatz, setTrinkgeldProzentsatz] = useState(5); // Provision in Prozent
  const [mwStSatz, setMwStSatz] = useState(19); // MwSt-Satz
  const [ergebnis, setErgebnis] = useState(null); // Ergebnis: Provision und Gesamtsumme

  // Eingabe für den Betrag (mit MwSt)
  const handleBetragChange = (event) => {
    setBetrag(event.target.value);
  };

  // Eingabe für den Trinkgeld-/Provisionssatz
  const handleTrinkgeldChange = (event) => {
    setTrinkgeldProzentsatz(parseInt(event.target.value, 10));
  };

  // Eingabe für den MwSt-Satz
  const handleMwStChange = (event) => {
    setMwStSatz(parseInt(event.target.value, 10));
  };

  // Berechnung der Provision und des Endbetrags
  const berechneProvision = () => {
    const betragFloat = parseFloat(betrag);
    if (!isNaN(betragFloat)) {
      // Betrag ohne MwSt
      const betragOhneMwSt = betragFloat / (1 + mwStSatz / 100);
      // Berechnung der Provision
      const provisionBetrag = (betragOhneMwSt * trinkgeldProzentsatz) / 100;
      const gesamtBetrag = betragOhneMwSt - provisionBetrag;

      // Ergebnis setzen
      setErgebnis({ betragOhneMwSt, provisionBetrag, gesamtBetrag });
    } else {
      setErgebnis(null);
    }
  };

  return (
    <div className="App">
      <h1>Provisions Rechner</h1>
      <label>
        Verkaufspreis (mit MwSt):
        <input type="number" value={betrag} onChange={handleBetragChange} />
      </label>
      <br />
      <label>
        MwSt-Satz:
        <select value={mwStSatz} onChange={handleMwStChange}>
          <option value="19">19%</option>
          <option value="7">7%</option>
        </select>
      </label>
      <br />
      <label>
        Provision:
        <select value={trinkgeldProzentsatz} onChange={handleTrinkgeldChange}>
          <option value="5">5%</option>
          <option value="10">10%</option>
          <option value="15">15%</option>
          <option value="20">20%</option>
          <option value="25">25%</option>
          <option value="30">30%</option>
          <option value="35">35%</option>
          <option value="40">40%</option>
          <option value="45">45%</option>
          <option value="50">50%</option>
        </select>
      </label>
      <br />
      <button onClick={berechneProvision}>Berechnen</button>
      <br />
      {ergebnis && (
        <div>
          <p>Betrag ohne MwSt: {ergebnis.betragOhneMwSt.toFixed(2)}€</p>
          <p>Provision: {ergebnis.provisionBetrag.toFixed(2)}€</p>
          <p>Gesamt (nach Provision): {ergebnis.gesamtBetrag.toFixed(2)}€</p>
        </div>
      )}
      <footer>
        <p>2025 Hanno Liehmann</p>
      </footer>
    </div>
  );
}

export default App;
